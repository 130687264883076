import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    CreateTransactionPaymentMutation,
    PaymentParams,
    TransactionType,
    useCreateTransactionPaymentMutation,
} from '../../../generated/graphql'
import Loading from '../../loading'
import { addToQuery, evictQuery } from '../../../apollo/resolvers'
import { enqueueSnackbar } from 'notistack'
import TransactionForm from '../../transactions/transactionForm'

const PaySupplier = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [createTransactionPayment, { loading: createTransactionLoading }] =
        useCreateTransactionPaymentMutation()

    const submitTransaction = React.useCallback(
        (transaction: PaymentParams) => {
            if (!transaction.categoryId) {
                enqueueSnackbar('A category must be selected')
                return
            }

            if (!transaction.supplierId) {
                enqueueSnackbar(
                    'The supplier is missing, there is an error, please contact with the admin.'
                )
                return
            }

            createTransactionPayment({
                variables: {
                    data: transaction,
                },
                onCompleted(data: CreateTransactionPaymentMutation) {
                    evictQuery('transactionsSearch')
                    addToQuery(data.createTransactionPayment, 'transactions')
                    navigate(-1)
                },
            })
        },
        [createTransactionPayment, navigate]
    )

    return createTransactionLoading ? (
        <Loading />
    ) : (
        <TransactionForm
            transaction={{
                date: undefined,
                amount: 0,
                payment: undefined,
                paymentMethod: undefined,
                account: undefined,
                type: TransactionType.Expense,
            }}
            supplierId={id || ''}
            submit={submitTransaction}
        />
    )
}

export default React.memo(PaySupplier)
