import { Button, Divider, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import {
    Category,
    PaymentParams,
    Transaction,
    TransactionType,
    UpdatePaymentParams,
    useGetAccountsQuery,
    useGetCategoriesQuery,
    useGetPaymentMethodsQuery,
    useGetSupplierQuery,
} from '../../../generated/graphql'
import ItemSelector from '../../selectors/ItemSelector'
import Loading from '../../loading'
import DateField from '../../pickers/dateField'
import AutocompleteSelector from '../../selectors/AutocompleteSelector'

export type ComponentProps = {
    transaction: Transaction
    supplierId: string
    submit: (transaction: UpdatePaymentParams | PaymentParams) => void
}

const TransactionForm = ({
    transaction,
    supplierId,
    submit,
}: ComponentProps) => {
    const [updatedTransaction, setUpdatedTransaction] =
        React.useState<Transaction>(transaction)

    const [category, setCategory] = React.useState<Category | undefined>(
        transaction?.payment?.category
    )

    const { data: supplierData, loading: supplierLoading } =
        useGetSupplierQuery({
            variables: {
                data: { id: supplierId },
            },
            onCompleted(data) {
                setUpdatedTransaction({
                    ...updatedTransaction,
                    payment: {
                        supplier: data.supplier,
                    },
                })
                if (!category) setCategory(data?.supplier?.defaultCategory)
            },
        })

    const { data: accountsData, loading: accountsLoading } =
        useGetAccountsQuery()
    const { data: paymentMethodsData, loading: paymentMethodsLoading } =
        useGetPaymentMethodsQuery()

    const { data: categoriesData, loading: categoriesLoading } =
        useGetCategoriesQuery()

    const supplier = React.useMemo(() => {
        return supplierData?.supplier
    }, [supplierData?.supplier])

    const handleAmountChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target
            setUpdatedTransaction({
                ...updatedTransaction,
                [name]: parseFloat(value),
            })
        },
        [setUpdatedTransaction, updatedTransaction]
    )

    return supplierLoading ? (
        <Loading />
    ) : (
        <Grid>
            <Grid>
                <Typography>Supplier: {supplier?.name}</Typography>
                <Typography>Email: {supplier?.email}</Typography>
                <Typography>Phone: {supplier?.phone}</Typography>
                <Typography>NIF: {supplier?.nif} hours</Typography>
                <Typography>
                    Default category: {supplier?.defaultCategory?.name}
                </Typography>
            </Grid>
            <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
            <Grid container gap={3} flexDirection={'column'} component="form">
                <DateField
                    value={updatedTransaction.date}
                    onChange={(newValue) =>
                        setUpdatedTransaction({
                            ...updatedTransaction,
                            date: newValue,
                        })
                    }
                />

                <TextField
                    fullWidth
                    value={updatedTransaction.amount || ''}
                    label="Price"
                    type="number"
                    slot="shrink"
                    name="amount"
                    inputProps={{
                        step: 'any',
                    }}
                    InputProps={{
                        endAdornment: '€',
                    }}
                    onChange={handleAmountChange}
                />

                <ItemSelector
                    selectorKey="paymentMethod"
                    data={paymentMethodsData?.paymentMethods || []}
                    selectedItem={updatedTransaction.paymentMethod}
                    loading={paymentMethodsLoading}
                    setSelectedItem={(paymentMethod) => {
                        setUpdatedTransaction({
                            ...updatedTransaction,
                            paymentMethod,
                            account: !paymentMethod
                                ? undefined
                                : paymentMethod?.name !== 'Efectiu'
                                ? accountsData?.accounts?.find(
                                      (a) => a.name !== 'Efectiu'
                                  )
                                : accountsData?.accounts?.find(
                                      (a) => a.name === 'Efectiu'
                                  ),
                        })
                    }}
                />

                <ItemSelector
                    selectorKey="account"
                    data={accountsData?.accounts || []}
                    selectedItem={updatedTransaction.account}
                    loading={accountsLoading}
                    setSelectedItem={(account) => {
                        setUpdatedTransaction({
                            ...updatedTransaction,
                            account,
                        })
                    }}
                />

                <AutocompleteSelector<Category>
                    data={
                        categoriesData?.categories?.map((c) => ({
                            label: `${c.name}`,
                            id: c.id,
                        })) || []
                    }
                    fullWidth
                    selectedItemId={
                        category?.id || supplier?.defaultCategory?.id
                    }
                    selectorKey="category"
                    setSelectedItem={(category: Category | undefined) => {
                        setCategory(category)
                    }}
                    loading={categoriesLoading}
                />

                <TextField
                    multiline
                    fullWidth
                    value={updatedTransaction.notes || ''}
                    label="Notes"
                    type="text"
                    name="notes"
                    inputProps={{
                        step: 'any',
                    }}
                    onChange={(e) => {
                        setUpdatedTransaction((prev) => {
                            return {
                                ...prev,
                                notes: e.target.value,
                            }
                        })
                    }}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                        submit({
                            id: updatedTransaction.id,
                            accountId: updatedTransaction.account?.id,
                            amount: updatedTransaction.amount,
                            categoryId: category?.id,
                            date: updatedTransaction.date,
                            notes: updatedTransaction.notes,
                            paymentMethodId:
                                updatedTransaction.paymentMethod?.id,
                            supplierId: supplier?.id,
                            type: TransactionType.Expense,
                        })
                    }
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    )
}

export default React.memo(TransactionForm)
