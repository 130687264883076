import React from 'react'
import {
    Category,
    OrderType,
    useGetCategoriesQuery,
} from '../../../generated/graphql'
import { Button, Grid, MenuItem, Select, TextField } from '@mui/material'
import AutocompleteSelector from '../../selectors/AutocompleteSelector'
import { useSearchParams } from 'react-router-dom'
import DateUrlField from '../../pickers/dateUrlField'

const OrderSearchBar = () => {
    const [searchText, setSearchText] = React.useState<string>('')
    const [searchParams, setSearchParams] = useSearchParams()

    React.useEffect(() => {
        const text = searchParams.get('searchText')
        if (text) {
            setSearchText(text)
        }
    }, [searchParams])

    const { data: categoriesData, loading: loadingCategories } =
        useGetCategoriesQuery()

    const onSubmit = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            setSearchParams((params) => {
                params.delete('offset')
                params.delete('searchText')
                searchText && params.set('searchText', searchText)
                return params
            })
        },
        [searchText, setSearchParams]
    )

    return (
        <Grid
            component="form"
            onSubmit={onSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: 2,
                gap: 1,
            }}
        >
            <Grid display={'flex'} alignItems={'center'} gap={2}>
                <Grid flexGrow={1}>
                    <TextField
                        size="small"
                        fullWidth
                        id="search"
                        label="Search"
                        variant="outlined"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </Grid>

                <Grid flexGrow={1}>
                    <AutocompleteSelector<Category>
                        data={
                            categoriesData?.categories?.map((c) => ({
                                label: `${c.name}`,
                                id: c.id,
                            })) || []
                        }
                        fullWidth
                        selectedItemId={searchParams.get('category') || ''}
                        selectorKey="category"
                        setSelectedItem={(category: Category | undefined) =>
                            setSearchParams((params) => {
                                params.delete('offset')
                                params.delete('category')
                                category &&
                                    params.set('category', category.id || '')
                                return params
                            })
                        }
                        loading={loadingCategories}
                    />
                </Grid>
                <Grid flexGrow={1}>
                    <Select
                        size="small"
                        fullWidth
                        labelId={`orderType-selector"`}
                        id={`orderType-selector"`}
                        value={searchParams.get('orderType') || 'empty'}
                        onChange={(event) => {
                            const value = event.target.value
                            setSearchParams((params) => {
                                params.delete('offset')
                                if (value === 'empty')
                                    params.delete('orderType')
                                else params.set('orderType', value)
                                return params
                            })
                        }}
                    >
                        <MenuItem
                            key={`menuItemKey-orderType-empty`}
                            value={'empty'}
                        >
                            {' '}
                            Select order type
                        </MenuItem>
                        <MenuItem
                            key={`menuItemKey-article`}
                            value={OrderType.Article}
                        >
                            {' '}
                            {OrderType.Article}
                        </MenuItem>
                        <MenuItem
                            key={`menuItemKey-orderType-income`}
                            value={OrderType.Session}
                        >
                            {' '}
                            {OrderType.Session}
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid flexGrow={1}>
                    <Select
                        size="small"
                        fullWidth
                        labelId={`orderPayed-selector"`}
                        id={`orderPayed-selector"`}
                        value={searchParams.get('isPayed') || 'empty'}
                        onChange={(event) => {
                            const value = event.target.value
                            setSearchParams((params) => {
                                params.delete('offset')
                                if (value === 'empty') params.delete('isPayed')
                                else
                                    params.set(
                                        'isPayed',
                                        value === 'payed'
                                            ? 'payed'
                                            : 'not payed'
                                    )
                                return params
                            })
                        }}
                    >
                        <MenuItem
                            key={`menuItemKey-orderType-empty`}
                            value={'empty'}
                        >
                            {' '}
                            Select Payed or not
                        </MenuItem>
                        <MenuItem key={`menuItemKey-article`} value={'payed'}>
                            {' '}
                            Payed
                        </MenuItem>
                        <MenuItem
                            key={`menuItemKey-orderType-income`}
                            value={'not payed'}
                        >
                            {' '}
                            Not Payed
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid flexGrow={1}>
                    <DateUrlField field={'dateRangeFrom'} label={'Date from'} />
                </Grid>
                <Grid flexGrow={1}>
                    <DateUrlField field={'dateRangeTo'} label={'Date to'} />
                </Grid>
                <Button variant={'contained'} size={'small'} type="submit">
                    Search
                </Button>
            </Grid>
        </Grid>
    )
}

export default React.memo(OrderSearchBar)
