import React from 'react'
import IconButton from '@mui/material/IconButton'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useSearchParams } from 'react-router-dom'
import { SortDirection } from '../../../generated/graphql'
import Typography from '@mui/material/Typography'

type ComponentProps = {
    field: string
    label: string
}

const SortableHeader = ({ field, label }: ComponentProps) => {
    const [searchParams, setSearchParams] = useSearchParams()

    return (
        <>
            <IconButton
                size="small"
                onClick={() => {
                    setSearchParams((queryParams) => {
                        const selectedField = searchParams.get('sort')
                        if (selectedField && selectedField !== field) {
                            queryParams.delete('sort')
                            queryParams.delete('direction')
                        }

                        const direction = queryParams.get('direction')
                        if (direction === SortDirection.Desc) {
                            queryParams.set('sort', field)
                            queryParams.set('direction', SortDirection.Asc)
                        } else if (direction === SortDirection.Asc) {
                            queryParams.delete('sort')
                            queryParams.delete('direction')
                        } else {
                            queryParams.set('sort', field)
                            queryParams.set('direction', SortDirection.Desc)
                        }

                        return queryParams
                    })
                }}
            >
                {searchParams.get('sort') === field &&
                searchParams.get('direction') === SortDirection.Asc ? (
                    <ArrowDropUpIcon />
                ) : searchParams.get('sort') === field &&
                  searchParams.get('direction') === SortDirection.Desc ? (
                    <ArrowDropDownIcon />
                ) : (
                    <ArrowRightIcon />
                )}
            </IconButton>
            <Typography sx={{ marginRight: 3 }} fontSize={12}>
                {label}
            </Typography>
        </>
    )
}

export default React.memo(SortableHeader)
