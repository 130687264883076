import React from 'react'
import {
    Category,
    Person,
    useGetCategoriesQuery,
    useGetCustomersQuery,
    useGetTherapistsQuery,
} from '../../../generated/graphql'
import { Grid } from '@mui/material'
import AutocompleteSelector from '../../selectors/AutocompleteSelector'
import { useSearchParams } from 'react-router-dom'
import DateUrlField from '../../pickers/dateUrlField/DateUrlField'

const SessionSearchBar = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const { data: customersData, loading: loadingCustomers } =
        useGetCustomersQuery()

    const { data: therapistData, loading: loadingTherapists } =
        useGetTherapistsQuery()

    const { data: categoriesData, loading: loadingCategories } =
        useGetCategoriesQuery()

    const customers = React.useMemo(() => {
        return customersData?.customers || []
    }, [customersData?.customers])

    const therapists = React.useMemo(() => {
        return therapistData?.therapists || []
    }, [therapistData?.therapists])

    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 2,
                gap: 1,
            }}
        >
            <Grid flexGrow={1}>
                <AutocompleteSelector<Person>
                    data={
                        customers.map((c) => ({
                            label: `${c.name} ${c.surname}`,
                            id: c.id,
                        })) || []
                    }
                    fullWidth
                    selectedItemId={searchParams.get('customer') || undefined}
                    selectorKey="customer"
                    setSelectedItem={(item: Person | undefined) => {
                        setSearchParams((params) => {
                            params.delete('customer')
                            item && params.set('customer', item.id || '')
                            return params
                        })
                    }}
                    loading={loadingCustomers}
                />
            </Grid>

            <Grid flexGrow={1}>
                <AutocompleteSelector<Person>
                    data={
                        therapists.map((c) => ({
                            label: `${c.name} ${c.surname}`,
                            id: c.id,
                        })) || []
                    }
                    fullWidth
                    selectedItemId={searchParams.get('therapist') || undefined}
                    selectorKey="therapist"
                    setSelectedItem={(item: Person | undefined) => {
                        setSearchParams((params) => {
                            params.delete('therapist')
                            item && params.set('therapist', item.id || '')
                            return params
                        })
                    }}
                    loading={loadingTherapists}
                />
            </Grid>
            <Grid flexGrow={1}>
                <AutocompleteSelector<Category>
                    data={
                        categoriesData?.categories?.map((c) => ({
                            label: `${c.name}`,
                            id: c.id,
                        })) || []
                    }
                    fullWidth
                    selectedItemId={searchParams.get('category') || undefined}
                    selectorKey="category"
                    setSelectedItem={(category: Category | undefined) =>
                        setSearchParams((params) => {
                            params.delete('category')
                            category &&
                                params.set('category', category.id || '')
                            return params
                        })
                    }
                    loading={loadingCategories}
                />
            </Grid>

            <Grid flexGrow={1}>
                <DateUrlField field={'dateRangeFrom'} label={'Date from'} />
            </Grid>
            <Grid flexGrow={1}>
                <DateUrlField field={'dateRangeTo'} label={'Date to'} />
            </Grid>
        </Grid>
    )
}

export default React.memo(SessionSearchBar)
