import { Button, MenuItem, Select, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import ItemSelector from '../../selectors/ItemSelector/ItemSelector'
import {
    Category,
    TransactionType,
    useGetCategoriesQuery,
} from '../../../generated/graphql'
import { useSearchParams } from 'react-router-dom'
import DateUrlField from '../../pickers/dateUrlField'

const TransactionSearchBar = () => {
    const { data: categoriesData, loading: loadingCategories } =
        useGetCategoriesQuery({})

    const [searchParams, setSearchParams] = useSearchParams()

    const [searchText, setSearchText] = React.useState<string>('')

    React.useEffect(() => {
        const text = searchParams.get('searchText')
        if (text) setSearchText(text)
    }, [searchParams])

    const submitSearch = React.useCallback(
        (e: React.FormEvent) => {
            e.preventDefault()

            setSearchParams((params) => {
                params.delete('offset')
                params.delete('searchText')
                searchText && params.set('searchText', searchText || '')
                return params
            })
        },
        [searchText, setSearchParams]
    )

    return (
        <Grid
            component="form"
            onSubmit={submitSearch}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: 2,
                gap: 1,
            }}
        >
            <Grid display={'flex'} alignItems={'center'} gap={2}>
                <Grid flexGrow={1}>
                    <TextField
                        size="small"
                        fullWidth
                        id="search"
                        label="Search"
                        variant="outlined"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </Grid>
                <Grid flexGrow={1}>
                    <ItemSelector<Category>
                        fullWidth
                        data={categoriesData?.categories || []}
                        selectorKey="category"
                        loading={loadingCategories}
                        selectedItem={
                            categoriesData?.categories?.find(
                                (cat) =>
                                    cat.name === searchParams.get('category')
                            ) || undefined
                        }
                        setSelectedItem={(category: Category | undefined) =>
                            setSearchParams((params) => {
                                params.delete('offset')
                                params.delete('category')
                                if (category?.name) {
                                    params.set('category', category.name)
                                }
                                return params
                            })
                        }
                    />
                </Grid>
                <Grid flexGrow={1}>
                    <Select
                        size="small"
                        fullWidth
                        labelId={`transactionType-selector"`}
                        id={`transactionType-selector"`}
                        value={searchParams.get('transactionType') || 'empty'}
                        onChange={(event) => {
                            setSearchParams((params) => {
                                params.delete('offset')
                                event.target.value === 'empty'
                                    ? searchParams.delete('transactionType')
                                    : searchParams.set(
                                          'transactionType',
                                          event.target.value
                                      )
                                return params
                            })
                        }}
                    >
                        <MenuItem
                            key={`menuItemKey-transactionType-empty`}
                            value={'empty'}
                        >
                            {' '}
                            Select transaction type
                        </MenuItem>
                        <MenuItem
                            key={`menuItemKey-income`}
                            value={TransactionType.Income}
                        >
                            {' '}
                            {TransactionType.Income}
                        </MenuItem>
                        <MenuItem
                            key={`menuItemKey-transactionType-income`}
                            value={TransactionType.Expense}
                        >
                            {' '}
                            {TransactionType.Expense}
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid flexGrow={1}>
                    <DateUrlField field={'dateRangeFrom'} label={'Date from'} />
                </Grid>
                <Grid flexGrow={1}>
                    <DateUrlField field={'dateRangeTo'} label={'Date to'} />
                </Grid>
                <Button type={'submit'} variant={'contained'} size={'small'}>
                    SEARCH
                </Button>
            </Grid>
        </Grid>
    )
}

export default React.memo(TransactionSearchBar)
