import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'

import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import LogoutIcon from '@mui/icons-material/Logout'
import AppBar from './AppBar'
import Drawer from './Drawer'
import MenuItems from './MenuItems'
import { deleteSession } from '../../lib/sessionStorage'
import { useNavigate } from 'react-router-dom'
import useTitleLocation from '../../hooks/useLocationTitle'

type ComponentProps = {
    children: React.ReactNode
}

const Dashboard = ({ children }: ComponentProps) => {
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(true)
    const toggleDrawer = () => {
        setOpen(!open)
    }

    const logout = React.useCallback(() => {
        deleteSession()
        window.location.href = '/login'
    }, [])

    const navigateBack = React.useCallback(() => {
        navigate(-1)
    }, [navigate])

    const { title } = useTitleLocation()

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        aria-label="Navigate back"
                        onClick={navigateBack}
                        sx={{
                            color: 'inherit',
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <ChevronLeftIcon sx={{ color: 'inherit' }} />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {title}
                    </Typography>
                    <IconButton onClick={logout} sx={{ color: 'inherit' }}>
                        <LogoutIcon sx={{ color: 'inherit' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton
                        onClick={navigateBack}
                        sx={{
                            ...(!open && { display: 'none' }),
                        }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <MenuItems />
                    <Divider sx={{ my: 1 }} />
                    <IconButton
                        // edge="start"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            color: 'inherit',
                            marginLeft: 1,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon sx={{ color: 'inherit' }} />
                    </IconButton>
                    <IconButton
                        // edge="start"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            color: 'inherit',
                            marginLeft: 1,
                            ...(!open && { display: 'none' }),
                        }}
                    >
                        <ChevronLeftIcon sx={{ color: 'inherit' }} />
                    </IconButton>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />

                <Grid
                    id="dashboardContainer"
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        padding: 4,
                    }}
                >
                    {children}
                </Grid>
            </Box>
        </Box>
    )
}

export default React.memo(Dashboard)
