import { Box, Grid, IconButton } from '@mui/material'
import React from 'react'
import {
    useDeleteCustomerMutation,
    useGetCustomersQuery,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate, useSearchParams } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'

import SpaIcon from '@mui/icons-material/Spa'
import DeleteButton from '../../../deleteButton'
import StorefrontIcon from '@mui/icons-material/Storefront'
import dayjs from 'dayjs'
import PeopleDataGrid from '../../../dataGrid/peopleDataGrid'
import { normalizeString } from '../../../../lib/normalize'

const CustomersTable = () => {
    const navigate = useNavigate()
    const [deleting, setDeleteting] = React.useState<string[]>([])
    const [searchParams] = useSearchParams()
    const [deleteCustomer, { loading: loadingDelete }] =
        useDeleteCustomerMutation()

    const { data, loading } = useGetCustomersQuery()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deleteCustomer({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: () => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: ['getCustomers', 'getPeople'],
            })
        },
        [deleteCustomer, deleting, setDeleteting]
    )

    const customers = React.useMemo(() => {
        return data?.customers || []
    }, [data])
    const columns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'email', headerName: 'Email', flex: 1 },
            { field: 'dni', headerName: 'DNI', flex: 1 },
            { field: 'phone', headerName: 'Phone', flex: 1 },
            { field: 'signatureDate', headerName: 'Sig Date', flex: 1 },
            { field: 'documentType', headerName: 'Sig Type', flex: 1 },
            { field: 'diffusion', headerName: 'Dif', flex: 1 },
            { field: 'wantReceiveInfo', headerName: 'Info?', flex: 1 },
            { field: 'imageRights', headerName: 'Rights?', flex: 1 },
            { field: 'contactChannel', headerName: 'Contact', flex: 1 },
            { field: 'howDiscovered', headerName: 'Discovered?', flex: 1 },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 200,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        <IconButton onClick={() => navigate(`${row.id}`)}>
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton
                            onClick={() =>
                                navigate(`/orders/new/customer/${row.id}`)
                            }
                        >
                            <StorefrontIcon />
                        </IconButton>
                        <IconButton
                            onClick={() =>
                                navigate('/sessions/new', {
                                    state: row,
                                })
                            }
                        >
                            <SpaIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => navigate(`update/${row.id}`)}
                        >
                            <EditIcon />
                        </IconButton>
                        {loadingDelete && deleting.includes(row.id || '') ? (
                            <Box
                                sx={{ marginLeft: '8px', marginRight: '8px' }}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Loading size={'1.6em'} />
                            </Box>
                        ) : (
                            <DeleteButton onDelete={handleDelete} item={row} />
                        )}
                    </Grid>
                ),
            },
        ],
        [handleDelete, navigate, deleting, loadingDelete]
    )

    const rows = React.useMemo(() => {
        const search = searchParams.get('searchText')
        const searched = search
            ? customers.filter(
                  (c) =>
                      normalizeString(c.fullName).includes(search) ||
                      normalizeString(c.email).includes(search) ||
                      normalizeString(c.phone).includes(search) ||
                      normalizeString(c.dni).includes(search)
              )
            : customers
        return searched.map((customer) => {
            return {
                id: customer.id,
                name: customer.fullName,
                email: customer.email,
                phone: customer.phone,
                dni: customer.dni,
                signatureDate:
                    dayjs(customer.customer?.signatureDate).format(
                        'DD/MM/YYYY'
                    ) !== 'Invalid Date'
                        ? dayjs(customer.customer?.signatureDate).format(
                              'DD/MM/YYYY'
                          )
                        : '',
                documentType: customer.customer?.documentType,
                diffusion: customer.customer?.diffusion,
                wantReceiveInfo:
                    customer.customer?.wantReceiveInfo === true
                        ? 'yes'
                        : customer.customer?.wantReceiveInfo === false
                        ? 'no'
                        : null,
                imageRights:
                    customer.customer?.imageRights === true
                        ? 'yes'
                        : customer.customer?.imageRights === false
                        ? 'no'
                        : null,
                contactChannel: customer.customer?.contactChannel,
                howDiscovered: customer.customer?.howDiscovered,
            }
        })
    }, [customers, searchParams])

    return loading ? (
        <Loading />
    ) : (
        <PeopleDataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            density="compact"
        />
    )
}

export default React.memo(CustomersTable)
