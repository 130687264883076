import { Box, Grid, IconButton } from '@mui/material'
import React from 'react'
import {
    useDeleteTherapistMutation,
    useGetTherapistsQuery,
} from '../../../../generated/graphql'
import Loading from '../../../loading'
import { useNavigate, useSearchParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteButton from '../../../deleteButton'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import PeopleDataGrid from '../../../dataGrid/peopleDataGrid'
import { normalizeString } from '../../../../lib/normalize'

const TherapistTable = () => {
    const navigate = useNavigate()
    const [deleting, setDeleteting] = React.useState<string[]>([])
    const [searchParams] = useSearchParams()

    const [deleteTherapist, { loading: loadingDelete }] =
        useDeleteTherapistMutation()

    const { data, loading } = useGetTherapistsQuery()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deleteTherapist({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: () => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: ['getTherapists', 'getPeople'],
            })
        },
        [deleteTherapist, deleting, setDeleteting]
    )

    const therapists = React.useMemo(() => {
        return data?.therapists || []
    }, [data])

    const columns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'email', headerName: 'Email', flex: 1 },
            { field: 'phone', headerName: 'Phone', flex: 1 },
            { field: 'dni', headerName: 'DNI', flex: 1 },
            { field: 'defaultPrice', headerName: 'Default price', flex: 1 },
            { field: 'conditions', headerName: 'Conditions', flex: 1 },
            { field: 'irpf', headerName: 'IRPF', flex: 1 },
            { field: 'defaultCategory', headerName: 'Category', flex: 1 },
            { field: 'licenseCode', headerName: 'License Code', flex: 1 },
            {
                field: 'disabled',
                headerName: 'Disabled',
                flex: 1,
            },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 150,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        {row.disabled ? (
                            <PersonOffIcon
                                sx={{
                                    marginRight: 1.5,
                                    color: 'secondary.main',
                                }}
                            />
                        ) : (
                            <HowToRegIcon
                                sx={{ marginRight: 1.5, color: 'primary.main' }}
                            />
                        )}
                        <IconButton onClick={() => navigate(`${row.id}`)}>
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => navigate(`update/${row.id}`)}
                        >
                            <EditIcon />
                        </IconButton>
                        {loadingDelete && deleting.includes(row.id || '') ? (
                            <Box
                                sx={{ marginLeft: '8px', marginRight: '8px' }}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Loading size={'1.6em'} />
                            </Box>
                        ) : (
                            <DeleteButton onDelete={handleDelete} item={row} />
                        )}
                    </Grid>
                ),
            },
        ],
        [handleDelete, navigate, loadingDelete, deleting]
    )

    const rows = React.useMemo(() => {
        const search = searchParams.get('searchText')

        const searched = search
            ? therapists.filter(
                  (t) =>
                      normalizeString(t.fullName).includes(search) ||
                      normalizeString(t.email).includes(search) ||
                      normalizeString(t.phone).includes(search) ||
                      normalizeString(t.dni).includes(search)
              )
            : therapists

        return searched.map((therapist) => {
            return {
                id: therapist.id,
                name: therapist.fullName,
                email: therapist.email,
                phone: therapist.phone,
                dni: therapist.dni,
                defaultPrice: therapist.therapist?.defaultPrice
                    ? `${therapist.therapist?.defaultPrice}€`
                    : 'Nan',
                conditions: `${therapist.therapist?.conditions}%`,
                irpf: `${therapist.therapist?.irpf}%`,
                defaultCategory:
                    therapist.therapist?.defaultCategory?.name ||
                    'Not assigned',
                licenseCode: therapist.therapist?.licenseCode,
                disabled: !!therapist.therapist?.disabled,
            }
        })
    }, [therapists, searchParams])

    return loading ? (
        <Loading />
    ) : (
        <PeopleDataGrid
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        disabled: false,
                        id: false,
                    },
                },
            }}
            columns={columns}
            rows={rows}
            loading={loading}
            density="compact"
        />
    )
}

export default React.memo(TherapistTable)
