import React from 'react'
import {
    SortDirection,
    TransactionType,
    useDeleteTransactionMutation,
    useSearchTransactionsQuery,
} from '../../generated/graphql'
import { transactionOrderCategories } from '../../lib/transaction'
import Paginator from '../paginator'
import TransactionSearchBar from '../searchBar/transactionSerachBar'
import { Grid, IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useNavigate, useSearchParams } from 'react-router-dom'
import dayjs from '../../lib/dayjs'
import CustomDataGrid from '../customDataGrid'
import EditIcon from '@mui/icons-material/Edit'
import DeleteButton from '../deleteButton'
import SortableHeader from '../dataGrid/sortableHeader'
import useUrlDateRangeParams from '../../hooks/useUrlDateRangeParams'

const Transactions = () => {
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()

    const dateRange = useUrlDateRangeParams()

    const { data: transactionsData, loading: loadingTransactions } =
        useSearchTransactionsQuery({
            variables: {
                data: {
                    filters: {
                        searchText: searchParams.get('searchText') || undefined,
                        category: searchParams.get('category') || undefined,
                        transactionType:
                            (searchParams.get(
                                'transactionType'
                            ) as TransactionType) || undefined,
                        dateRange,
                    },
                    sort: {
                        field: searchParams.get('sort') || undefined,
                        direction: !searchParams.get('direction')
                            ? undefined
                            : searchParams.get('direction') ===
                              SortDirection.Asc
                            ? SortDirection.Asc
                            : SortDirection.Desc,
                    },
                    offset: parseInt(searchParams.get('offset') || '0') || 0,
                    limit: parseInt(searchParams.get('limit') || '50') || 50,
                },
            },
        })

    const transactions = React.useMemo(() => {
        return transactionsData?.transactionsSearch?.transactions || []
    }, [transactionsData?.transactionsSearch?.transactions])

    const totalTransactions = React.useMemo((): number => {
        return transactionsData?.transactionsSearch?.total || 0
    }, [transactionsData?.transactionsSearch?.total])

    const [deleteTransaction, { loading: deleteLoading }] =
        useDeleteTransactionMutation()

    const columns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', flex: 1 },
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'date',
                headerName: 'Date',
                flex: 1,
                disableColumnMenu: true,
                sortable: false,
                renderHeader: () => {
                    return <SortableHeader field={'date'} label={'Date'} />
                },
            },
            {
                field: 'type',
                headerName: 'Income/Expense',
                flex: 1,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'amount',
                headerName: 'Amount',
                flex: 1,
                disableColumnMenu: true,
                sortable: false,
                renderHeader: () => {
                    return <SortableHeader field={'amount'} label={'Amount'} />
                },
            },
            {
                field: 'categories',
                headerName: 'Categories',
                flex: 1,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'contribution',
                headerName: 'Contribution',
                flex: 1,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'account',
                headerName: 'Account',
                flex: 1,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        {!row.contribution &&
                        row.type === TransactionType.Income ? (
                            <IconButton
                                onClick={() => navigate(`invoice/${row.id}`)}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={() => navigate(`update/${row.id}`)}
                            >
                                <EditIcon />
                            </IconButton>
                        )}

                        <DeleteButton
                            onDelete={() =>
                                deleteTransaction({
                                    variables: {
                                        data: {
                                            id: row.id,
                                        },
                                    },
                                    refetchQueries: [
                                        'searchTransactions',
                                        'getCustomerResume',
                                    ],
                                })
                            }
                            item={{
                                ...row,
                                name: `transaction from ${row.name}`,
                            }}
                        />
                    </Grid>
                ),
            },
        ],
        [navigate, deleteTransaction]
    )

    const rows = React.useMemo(() => {
        return transactions.map((transaction) => {
            return {
                id: transaction.id,
                name: transaction.payment?.supplier
                    ? transaction.payment.supplier?.name
                    : transaction.order?.customer?.fullName,
                date: dayjs(transaction.date).format('DD/MM/YYYY'),
                categories: transactionOrderCategories(transaction),
                contribution: transaction.contribution
                    ? transaction.contribution.customer?.fullName ||
                      'No customer contribution'
                    : null,
                type: transaction.type,
                amount: `${transaction.amount}€`,
                account: transaction.account?.name,
            }
        })
    }, [transactions])

    return (
        <>
            <Paginator
                sx={{ marginTop: 2, marginBottom: 2 }}
                total={totalTransactions}
                offset={parseInt(searchParams.get('offset') || '0')}
                limit={parseInt(searchParams.get('limit') || '50')}
                onChangeOffset={(offset) => {
                    setSearchParams((params) => {
                        params.set('offset', offset.toString())
                        return params
                    })
                }}
                onChangeLimit={(limit) => {
                    setSearchParams((params) => {
                        params.set('limit', limit.toString())
                        return params
                    })
                }}
            />
            <TransactionSearchBar />
            <CustomDataGrid
                columns={columns}
                rows={rows}
                loading={loadingTransactions || deleteLoading}
                density="compact"
                hideFooter
            />
        </>
    )
}

export default React.memo(Transactions)
