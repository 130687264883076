import moment from 'moment'
import { useSearchParams } from 'react-router-dom'

const useUrlDateRangeParams = () => {
    const [searchParams] = useSearchParams()

    return {
        from: searchParams.get('dateRangeFrom')
            ? moment(searchParams.get('dateRangeFrom'), 'DD-MM-YYYY').toDate()
            : undefined,
        to: searchParams.get('dateRangeTo')
            ? moment(searchParams.get('dateRangeTo'), 'DD-MM-YYYY').toDate()
            : undefined,
    }
}

export default useUrlDateRangeParams
