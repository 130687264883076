import React from 'react'
import DateField from '../dateField'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'

type ComponentProps = {
    label: string
    field: string
    cleanOffset?: boolean
}

const DateUrlField = ({ field, label, cleanOffset = true }: ComponentProps) => {
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <DateField
            label={label}
            value={
                searchParams.get(field)
                    ? moment(searchParams.get(field), 'DD-MM-YYYY').toDate()
                    : undefined
            }
            onChange={(newDate) =>
                setSearchParams((params) => {
                    cleanOffset && params.delete('offset')
                    params.delete(field)
                    newDate &&
                        params.set(
                            field,

                            moment(newDate).format('DD-MM-YYYY')
                        )
                    return params
                })
            }
        />
    )
}

export default React.memo(DateUrlField)
