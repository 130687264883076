import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CustomDataGrid from '../../customDataGrid'
import { DataGridProps } from '@mui/x-data-grid'
import { normalizeString } from '../../../lib/normalize'

type ComponentProps = DataGridProps & {}

const ArticlesDataGrid = (props: ComponentProps) => {
    const navigate = useNavigate()
    const [searchText, setSearchText] = React.useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    React.useEffect(() => {
        setSearchText(searchParams.get('searchText') || '')
    }, [searchParams])

    const setSearch = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            setSearchParams((params) => {
                params.delete('searchText')
                searchText &&
                    params.set('searchText', normalizeString(searchText))
                return params
            })
        },
        [setSearchParams, searchText]
    )

    return (
        <>
            <Grid
                component={'form'}
                display={'flex'}
                alignItems={'center'}
                sx={{ marginBottom: 2 }}
                onSubmit={setSearch}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    size={'small'}
                    fullWidth
                    value={searchText}
                    onChange={(e) =>
                        setSearchText((e.target.value || '').toLowerCase())
                    }
                />
                <Button variant="contained" sx={{ marginLeft: 2 }}>
                    seach
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginLeft: 2 }}
                    onClick={() => navigate('new')}
                >
                    Add
                </Button>
            </Grid>
            <CustomDataGrid {...props} />
        </>
    )
}

export default React.memo(ArticlesDataGrid)
