import { Button, Divider, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
    Transaction,
    TransactionType,
    useCreateTransactionOrderMutation,
    useGetAccountsQuery,
    useGetPaymentMethodsQuery,
    useGetOrderQuery,
} from '../../../generated/graphql'
import Loading from '../../loading'
import ItemSelector from '../../selectors/ItemSelector'
import { orderPrice } from '../../../lib/Order'

const PayOrder = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [transaction, setTransaction] = React.useState<Transaction>({
        amount: 0,
        order: undefined,
        paymentMethod: undefined,
        account: undefined,
        type: TransactionType.Income,
        notes: '',
    })

    const { data: orderData, loading: loadingOrder } = useGetOrderQuery({
        variables: {
            data: { id },
        },
        onCompleted(data) {
            const price = data.order ? orderPrice(data.order) : 0
            setTransaction({
                ...transaction,
                date: data.order?.date,
                amount: price,
                order: data.order,
            })
        },
    })
    const { data: accountsData, loading: accountsLoading } =
        useGetAccountsQuery()
    const { data: paymentMethodsData, loading: paymentMethodsLoading } =
        useGetPaymentMethodsQuery()

    const order = React.useMemo(() => {
        return orderData?.order
    }, [orderData?.order])

    const [createTransaction, { loading: createTransactionLoading }] =
        useCreateTransactionOrderMutation()

    const payOrder = React.useCallback(() => {
        createTransaction({
            variables: {
                data: {
                    date: transaction.date,
                    amount: transaction.amount,
                    orderId: transaction.order?.id,
                    paymentMethodId: transaction.paymentMethod?.id,
                    accountId: transaction.account?.id,
                    type: transaction.type,
                    notes: transaction.notes,
                },
            },
            onCompleted() {
                navigate(-2)
            },
            refetchQueries: ['getCustomerResume', 'searchTransactions'],
        })
    }, [transaction, createTransaction, navigate])

    const handleAmountChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target
            setTransaction({
                ...transaction,
                [name]: parseFloat(value),
            })
        },
        [setTransaction, transaction]
    )

    return loadingOrder || createTransactionLoading ? (
        <Loading />
    ) : (
        <Grid>
            <Grid>
                <Typography>Customer: {order?.customer?.name}</Typography>
                <Typography>Date: {order?.date}</Typography>
            </Grid>
            <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
            <Grid container gap={3} flexDirection={'column'} component="form">
                <ItemSelector
                    selectorKey="paymentMethod"
                    data={paymentMethodsData?.paymentMethods || []}
                    selectedItem={transaction.paymentMethod}
                    loading={paymentMethodsLoading}
                    setSelectedItem={(paymentMethod) => {
                        setTransaction({
                            ...transaction,
                            paymentMethod,
                            account: !paymentMethod
                                ? undefined
                                : paymentMethod?.name !== 'Efectiu'
                                ? accountsData?.accounts?.find(
                                      (a) => a.name !== 'Efectiu'
                                  )
                                : accountsData?.accounts?.find(
                                      (a) => a.name === 'Efectiu'
                                  ),
                        })
                    }}
                />

                <ItemSelector
                    selectorKey="account"
                    data={accountsData?.accounts || []}
                    selectedItem={transaction.account}
                    loading={accountsLoading}
                    setSelectedItem={(account) => {
                        setTransaction({
                            ...transaction,
                            account,
                        })
                    }}
                />

                <TextField
                    fullWidth
                    value={transaction.amount}
                    label="Price"
                    type="number"
                    slot="shrink"
                    name="amount"
                    onChange={handleAmountChange}
                />

                <TextField
                    multiline
                    fullWidth
                    value={transaction.notes}
                    label="Notes"
                    type="text"
                    name="notes"
                    onChange={(e) =>
                        setTransaction((prev) => ({
                            ...prev,
                            notes: e.target.value,
                        }))
                    }
                />

                <Button variant="contained" color="primary" onClick={payOrder}>
                    Submit
                </Button>
            </Grid>
        </Grid>
    )
}

export default React.memo(PayOrder)
