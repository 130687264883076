import React from 'react'
import {
    useDeleteArticleMutation,
    useGetArticlesQuery,
} from '../../generated/graphql'
import Loading from '../loading'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import DeleteButton from '../deleteButton'
import StorefrontIcon from '@mui/icons-material/Storefront'
import ArticlesDataGrid from '../dataGrid/articlesDataGrid/ArticlesDataGrid'
import { normalizeString } from '../../lib/normalize'

const Articles = () => {
    const navigate = useNavigate()
    const { data: articlesData, loading } = useGetArticlesQuery()

    const [deleting, setDeleteting] = React.useState<string[]>([])

    const [searchParams] = useSearchParams()

    const [deleteArticle, { loading: loadingDelete }] =
        useDeleteArticleMutation()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deleteArticle({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: () => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: ['getArticles'],
            })
        },
        [deleteArticle, deleting, setDeleteting]
    )

    const articles = React.useMemo(
        () => articlesData?.articles || [],
        [articlesData]
    )

    const columns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', flex: 0 },
            { field: 'name', headerName: 'Name', flex: 1 },
            { field: 'price', headerName: 'Price', flex: 1 },
            {
                field: 'defaultCategory',
                headerName: 'Default category',
                flex: 1,
            },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 120,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        <IconButton
                            onClick={() => navigate(`update/${row.id}`)}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={() =>
                                navigate(`/orders/new/article/${row.id}`)
                            }
                        >
                            <StorefrontIcon />
                        </IconButton>
                        {loadingDelete && deleting.includes(row.id || '') ? (
                            <Box
                                sx={{ marginLeft: '8px', marginRight: '8px' }}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Loading size={'1.6em'} />
                            </Box>
                        ) : (
                            <DeleteButton onDelete={handleDelete} item={row} />
                        )}
                    </Grid>
                ),
            },
        ],
        [loadingDelete, deleting, handleDelete, navigate]
    )

    const rows = React.useMemo(() => {
        const search = searchParams.get('searchText') || ''
        const searched = search
            ? articles.filter(
                  (c) =>
                      normalizeString(c.name).includes(search) ||
                      normalizeString(c.defaultCategory?.name).includes(
                          search
                      ) ||
                      normalizeString(c.price?.toString()).includes(search)
              )
            : articles
        return searched.map((article) => {
            return {
                id: article.id,
                name: article.name,
                price: `${article.price} €`,
                defaultCategory: article.defaultCategory?.name,
            }
        })
    }, [articles, searchParams])

    return loading ? (
        <Loading />
    ) : (
        <ArticlesDataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            density="compact"
        />
    )
}

export default React.memo(Articles)
