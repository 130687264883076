import React from 'react'
import { useLocation } from 'react-router-dom'
import { isObjectId } from '../lib/objectId'
import {
    useGetAccountLazyQuery,
    useGetArticleLazyQuery,
    useGetCategoryLazyQuery,
    useGetPaymentMethodLazyQuery,
    useGetPersonLazyQuery,
    useGetTransactionLazyQuery,
} from '../generated/graphql'
import Loading from '../components/loading'

const useTitleLocation = () => {
    const location = useLocation()

    const [title, setTitle] = React.useState<string>('Dashboard')

    const genericTitle = React.useMemo(() => {
        const relativePath = location.pathname.replace('/', '')
        return relativePath
            ? relativePath.toUpperCase().replaceAll('/', ' > ')
            : 'DASHBOARD'
    }, [location])

    const [getPerson, { loading: personLoading }] = useGetPersonLazyQuery()
    const [getTransaction, { loading: transactionLoading }] =
        useGetTransactionLazyQuery()
    const [getArticle, { loading: articleLoading }] = useGetArticleLazyQuery()
    const [getCategory, { loading: categoryLoading }] =
        useGetCategoryLazyQuery()

    const [getAccount, { loading: accountLoading }] = useGetAccountLazyQuery()
    const [getPaymentMethod, { loading: paymentMethodLoading }] =
        useGetPaymentMethodLazyQuery()

    const titleLoading = React.useMemo(() => {
        return (
            personLoading ||
            transactionLoading ||
            articleLoading ||
            categoryLoading ||
            accountLoading ||
            paymentMethodLoading
        )
    }, [
        personLoading,
        transactionLoading,
        articleLoading,
        categoryLoading,
        accountLoading,
        paymentMethodLoading,
    ])

    const setParsedTitle = React.useCallback(
        (name: string) => {
            const splittedPath = location.pathname.split('/')
            const replaced = splittedPath.map((item) =>
                isObjectId(item) ? name : item
            )
            setTitle(replaced.join(' > ').toUpperCase())
        },
        [location.pathname]
    )

    React.useEffect(() => {
        const splittedPath = location.pathname.split('/')
        const objectId = splittedPath.find((pathPart) => isObjectId(pathPart))

        if (!objectId) {
            setTitle(genericTitle)
            return
        }

        if (location.pathname.startsWith('/people')) {
            getPerson({
                variables: { data: { id: objectId } },
                onCompleted(data) {
                    setParsedTitle(data?.person?.fullName || 'Invalid name')
                },
            })
        } else if (location.pathname.startsWith('/transactions')) {
            getTransaction({
                variables: { data: { id: objectId } },
                onCompleted(data) {
                    setParsedTitle(
                        `Transaction of ${
                            data?.transaction?.contribution?.customer
                                ?.fullName ||
                            data?.transaction?.payment?.supplier?.name ||
                            data?.transaction?.order?.customer?.fullName
                        }`
                    )
                },
            })
        } else if (location.pathname.startsWith('/articles')) {
            getArticle({
                variables: {
                    data: {
                        id: objectId,
                    },
                },
                onCompleted(data) {
                    setParsedTitle(data.article?.name || '')
                },
            })
        } else if (location.pathname.startsWith('/categories')) {
            getCategory({
                variables: {
                    data: {
                        id: objectId,
                    },
                },
                onCompleted(data) {
                    setParsedTitle(data.category?.name || '')
                },
            })
        } else if (location.pathname.startsWith('/accounts')) {
            getAccount({
                variables: {
                    data: {
                        id: objectId,
                    },
                },
                onCompleted(data) {
                    setParsedTitle(data.account?.name || '')
                },
            })
        } else if (location.pathname.startsWith('/payment-methods')) {
            getPaymentMethod({
                variables: {
                    data: {
                        id: objectId,
                    },
                },
                onCompleted(data) {
                    setParsedTitle(data.paymentMethod?.name || '')
                },
            })
        }
    }, [
        location,
        genericTitle,
        getArticle,
        getPerson,
        getTransaction,
        setParsedTitle,
        getAccount,
        getCategory,
        getPaymentMethod,
    ])

    return titleLoading
        ? {
              title: <Loading />,
          }
        : {
              title: <>{title}</>,
          }
}

export default useTitleLocation
