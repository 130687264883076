import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    UpdatePaymentParams,
    useGetTransactionQuery,
    useUpdateTransactionPaymentMutation,
} from '../../../generated/graphql'
import Loading from '../../loading'
import TransactionForm from '../transactionForm/TransactionForm'
import { evictQuery } from '../../../apollo/resolvers'

const UpdateTransaction = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const { data: transactionData, loading: loadingTransaction } =
        useGetTransactionQuery({
            variables: {
                data: {
                    id,
                },
            },
        })

    const transaction = React.useMemo(() => {
        return transactionData?.transaction
    }, [transactionData])

    const [updateTransaction, { loading: updateTransactionLoading }] =
        useUpdateTransactionPaymentMutation()

    const submitTransaction = React.useCallback(
        (transaction: UpdatePaymentParams) => {
            updateTransaction({
                variables: {
                    data: transaction,
                },
                onCompleted() {
                    evictQuery('searchTransactions')
                    evictQuery('getCustomerResume')
                    navigate(-1)
                },
            })
        },
        [navigate, updateTransaction]
    )

    return !transaction?.payment ||
        loadingTransaction ||
        updateTransactionLoading ? (
        <Loading />
    ) : (
        <TransactionForm
            transaction={transaction}
            supplierId={transaction?.payment?.supplier?.id || ''}
            submit={submitTransaction}
        />
    )
}

export default React.memo(UpdateTransaction)
