import {
    Grid,
    MenuItem,
    Pagination,
    Select,
    SxProps,
    Theme,
    Typography,
} from '@mui/material'
import React from 'react'

type ComponentProps = {
    total: number
    offset: number
    limit: number
    onChangeOffset(offset: number): void
    onChangeLimit(limit: number): void
    sx?: SxProps<Theme> | undefined
}

const Paginator = ({
    total,
    offset,
    limit,
    onChangeOffset,
    onChangeLimit,
    sx,
}: ComponentProps) => {
    const [page, setPage] = React.useState(Math.ceil(offset / limit) + 1)

    const pageStart = offset + 1
    const pageEnd = offset + limit > total ? total : offset + limit

    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                ...(sx ? sx : {}),
            }}
        >
            <Typography>
                Shown {pageStart}/{pageEnd} of {total}
            </Typography>
            <Pagination
                page={page}
                onChange={(_, value) => {
                    if (value === 1) {
                        onChangeOffset(0)
                        setPage(1)
                    } else {
                        onChangeOffset(limit * (value - 1))
                        setPage(value)
                    }
                }}
                count={Math.ceil(total / limit)}
            />

            <Select
                size="small"
                id="search"
                label="Search"
                variant="outlined"
                value={limit}
                onChange={(e) =>
                    onChangeLimit(parseInt(e.target.value as string))
                }
            >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
            </Select>
        </Grid>
    )
}

export default React.memo(Paginator)
