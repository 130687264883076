import React from 'react'
import CustomDataGrid from '../../customDataGrid'
import { DataGridProps } from '@mui/x-data-grid'
import OrderSearchBar from '../../searchBar/orderSearchBar'

type ComponentProps = DataGridProps & {}

const OrdersDataGrid = (props: ComponentProps) => {
    return (
        <>
            <OrderSearchBar />
            <CustomDataGrid {...props} />
        </>
    )
}

export default React.memo(OrdersDataGrid)
