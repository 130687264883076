import React from 'react'
import {
    OrderType,
    useDeleteOrderMutation,
    useSearchOrdersQuery,
} from '../../generated/graphql'
import Loading from '../loading'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import { GridColDef } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

import { orderCategories, orderPrice } from '../../lib/Order'
import Paginator from '../paginator'
import DeleteButton from '../deleteButton'
import dayjs from '../../lib/dayjs'
import OrdersDataGrid from '../dataGrid/ordersDataGrid'
import useUrlDateRangeParams from '../../hooks/useUrlDateRangeParams'

const Orders = () => {
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()
    const dateRange = useUrlDateRangeParams()

    const { data: ordersData, loading } = useSearchOrdersQuery({
        variables: {
            data: {
                limit: parseInt(searchParams.get('limit') || '50'),
                offset: parseInt(searchParams.get('offset') || '0'),
                filters: {
                    searchText: searchParams.get('searchText') || undefined,
                    category: searchParams.get('category') || undefined,
                    orderType:
                        searchParams.get('orderType') === OrderType.Article
                            ? OrderType.Article
                            : OrderType.Session || undefined,
                    payed:
                        searchParams.get('isPayed') === 'payed'
                            ? true
                            : searchParams.get('isPayed') === 'not payed'
                            ? false
                            : undefined,
                    dateRange,
                },
            },
        },
    })
    const [deleting, setDeleteting] = React.useState<string[]>([])

    const [deleteOrder, { loading: loadingDelete }] = useDeleteOrderMutation()

    const handleDelete = React.useCallback(
        (id: string | undefined) => {
            if (!id) return
            setDeleteting([...deleting, id])
            deleteOrder({
                variables: {
                    data: {
                        id,
                    },
                },
                onCompleted: () => {
                    setDeleteting(deleting.filter((d) => d !== id))
                },
                refetchQueries: ['getOrders'],
            })
        },
        [deleteOrder, deleting, setDeleteting]
    )

    const orders = React.useMemo(
        () => ordersData?.ordersSearch?.orders || [],
        [ordersData]
    )

    const columns = React.useMemo(
        (): GridColDef[] => [
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'customer', headerName: 'Customer', flex: 1 },
            {
                field: 'categories',
                headerName: 'Categories',
                flex: 1,
            },
            {
                field: 'total',
                headerName: 'Total',
                flex: 1,
                type: 'number',
                maxWidth: 100,
                valueFormatter: (value) => {
                    return `${parseFloat(value).toFixed(2)} €`
                },
            },
            { field: 'date', type: 'date', headerName: 'Date', flex: 1 },
            {
                field: 'actions',
                headerName: 'Actions',
                width: 150,
                renderCell: ({ row }: { row: any }) => (
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        {row.payed ? (
                            <IconButton disabled={true}>
                                <CreditScoreIcon sx={{ color: 'green' }} />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={() => navigate(`pay/${row.id}`)}
                            >
                                <CreditScoreIcon />
                            </IconButton>
                        )}
                        <IconButton onClick={() => navigate(`${row.id}`)}>
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => navigate(`update/${row.id}`)}
                        >
                            <EditIcon />
                        </IconButton>
                        {loadingDelete && deleting.includes(row.id || '') ? (
                            <Box
                                sx={{ marginLeft: '8px', marginRight: '8px' }}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                <Loading size={'1.6em'} />
                            </Box>
                        ) : (
                            <DeleteButton
                                onDelete={handleDelete}
                                item={{
                                    ...row,
                                    name: `order from ${row.customer}`,
                                }}
                            />
                        )}
                    </Grid>
                ),
            },
        ],
        [loadingDelete, deleting, handleDelete, navigate]
    )

    const rows = React.useMemo(() => {
        return orders.map((order) => {
            return {
                id: order.id,
                customer: `${order.customer?.name} ${order.customer?.surname}`,
                date: dayjs(order.date).toDate(),
                categories: orderCategories(order),
                payed: order.transactionId ? true : false,
                total: `${orderPrice(order)}€`,
            }
        })
    }, [orders])

    return loading ? (
        <Loading />
    ) : (
        <>
            <Paginator
                sx={{ marginTop: 2, marginBottom: 2 }}
                total={ordersData?.ordersSearch?.total || 0}
                offset={parseInt(searchParams.get('offset') || '0') || 0}
                limit={parseInt(searchParams.get('limit') || '50') || 50}
                onChangeOffset={(offset) =>
                    setSearchParams((params) => {
                        params.set('offset', offset.toString())
                        return params
                    })
                }
                onChangeLimit={(limit) =>
                    setSearchParams((params) => {
                        params.set('limit', limit.toString())
                        return params
                    })
                }
            />
            <OrdersDataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                density="compact"
                hideFooter
            />
        </>
    )
}

export default React.memo(Orders)
